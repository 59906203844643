"use client"

import { useEffect } from "react"
import { ErrorFeedback } from "src/components/common/ErrorBoundaryFallback"
import { ErrorBoundaryProps } from "src/types/page"
import { reportError } from "src/utilities/error"

export default function GlobalErrorPage({ error }: ErrorBoundaryProps) {
  useEffect(() => {
    reportError("/global-error page was shown", error)
  }, [error])

  return (
    <html lang="de">
      <body>
        <ErrorFeedback />
      </body>
    </html>
  )
}
